import { http } from '@/http/axios.js'
// 品牌列表
export function getListAPI(params) {
  return http({
    url: '/company/car/brand/list',
    method: 'get',
    params
  })
}
// 详情
export function getDetailAPI(id) {
  return http({
    url: '/company/car/brand/detail',
    method: 'get',
    params: { id }
  })
}
// add
export function addAPI(data) {
  return http({
    url: '/company/car/brand/add',
    method: 'post',
    data
  })
}
// edit
export function editAPI(data) {
  return http({
    url: '/company/car/brand/edit',
    method: 'post',
    data
  })
}
// del
export function delAPI(id) {
  return http({
    url: '/company/car/brand/delete',
    method: 'post',
    data: { id }
  })
}
